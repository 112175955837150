<script setup lang="ts">
const { btnLink, type } = defineProps<{
  btnLink: any;
  type: String;
}>();

async function onClick(e: Event) {
  e.preventDefault();

  await navigateTo(btnLink.url);
}
</script>
<template>
  <button class="btn-read" :class="type" @click="onClick">
    <span class="btn-read-container-picto">
      <i role="presentation" class="extra-arrow-read" />
    </span>
    <span class="btn-text">
      {{ btnLink.label }}
    </span>
  </button>
</template>
<style lang="scss" scoped>
.btn-read {
  padding: 8px 13px 8px 30px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  transition: all ease-in-out 0.3s;
  max-height: 35px;
  min-height: 32px;
  position: relative;
  text-decoration: none;
  width: fit-content;

  &-container-picto {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 7px;
    display: flex;
    align-items: center;
    i {
      width: 15px;
      height: 15px;
      background-color: $white;
      border-radius: 50px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      position: relative;
      left: 0;
      transition: all ease-in-out 0.3s;
      &.extra-arrow-read {
        font-size: 8px;
        color: $primary-color;
        transition: all ease-in-out 0.3s;
      }
    }
  }

  .btn-text {
    text-transform: uppercase;
    font-size: 10px;
    transition: all ease-in-out 0.3s;
  }
  &.primary {
    background-color: $primary-color;
    color: $white;
    transition: all ease-in-out 0.3s;
  }
  &:hover {
    padding: 4px 13px 4px 30px;
    transition: all ease-in-out 0.3s;
    .btn-text {
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }
    .btn-read-container-picto {
      i {
        width: 24px;
        height: 24px;
        position: relative;
        left: calc(50% - 20px);
        transition: all ease-in-out 0.3s;
        &.extra-arrow-read {
          font-size: 12px;
          transition: all ease-in-out 0.3s;
        }
      }
    }

    &.primary {
      transition: all ease-in-out 0.3s;
    }
  }
}
</style>
